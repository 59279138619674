import React from 'react'
import { MarineLogo } from './MarineLogo'
import {
  CountWrapper,
  MarineKillCountWrapper,
  TextAndImageWrapper,
  TextWrapper
} from './killCountStyles'

export const MarineKillCount = ({ killCount }) => {
  return (
    <MarineKillCountWrapper>
      <TextAndImageWrapper>
        <MarineLogo />
        <TextWrapper>Marine</TextWrapper>
      </TextAndImageWrapper>
      <CountWrapper>{ killCount }</CountWrapper>
    </MarineKillCountWrapper>
  )
}
