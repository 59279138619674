import { Fragment, useMemo } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table'

const columns = [
  {
    header: 'icon',
    columns: [
      {
        id: 'icon',
        header: () => null,
        accessorFn: row => row.name
      }
    ]
  },
  {
    header: 'pickups',
    columns: [
      {
        id: 'pickups',
        cell: info => info.getValue(),
        accessorFn: row => row.pickups
      }
    ]
  },
  {
    header: 'time',
    columns: [
      {
        id: 'time',
        cell: info => info.getValue(),
        accessorFn: row => row.time
      }
    ]
  },
  {
    header: 'kills',
    columns: [
      {
        id: 'kills',
        cell: info => info.getValue(),
        accessorFn: row => row.kills
      },
    ]
  }
]

function prepareData(data) {
  const fullData = ['Quad Damage', 'Regeneration', 'Haste', 'Invisibility']

  const dataMap = data.reduce((acc, curr) => {
    acc[curr.name] = curr
    return acc
  }, {})

  return fullData.map(name => {
    if (dataMap[name]) {
      return dataMap[name]
    } else {
      return {
        name: name,
        pickups: 0,
        time: 0,
        kills: 0,
      }
    }
  })
}

export const PickupsTable = ({ row }) => {
  console.log(row)
  const pickups = useMemo(() => prepareData(row.original.powerups), [row.original.powerups])
  const table = useReactTable({
    data: pickups,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const THead = () => (
    <thead>
    {table.getHeaderGroups()
      .map(headerGroup => (
        <tr key={`-${headerGroup.id}`}>
          {headerGroup.headers.map(header => (
            <th key={`-${header.id}`} colSpan={header.colSpan}>
              {header.isPlaceholder ? null : (
                <div>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </div>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>

  )

  const TBody = () => (
    <tbody>
    {
      table.getRowModel()
        .rows
        .map(row => (
          <Fragment key={`-${row.id}`}>
            <tr onClick={row.getToggleExpandedHandler()}>
              {
                row.getVisibleCells()
                  .map(cell => (
                    <td key={`-${cell.id}`}>
                      {
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      }
                    </td>
                  ))
              }
            </tr>
          </Fragment>
        ))
    }
    </tbody>
  )

  return (
    <div>
      <table>
        <THead/>
        <TBody/>
      </table>
    </div>
  )
}
