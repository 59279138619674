import styled from 'styled-components'

export const KillsRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
`

export const TeamKillsWrapper = styled.div`
  color: #FF5678;
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: all-small-caps;
`

export const DeathRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 110px;
  
  svg {
    width: 20px;
    height: 14px;
  }
`

export const TopGunsWrapper = styled.div`
  div {
    margin: 0;
  }
  & svg {
    width: 20px;
    height: 14px;
    margin-right: 0px;
  }
`

export const SuicidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NameWrapper = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
`

export const NetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
`
