import React from 'react'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Route, Routes} from "react-router-dom";

import {queryClient} from '../query'
import {Match} from "../Match";
import {NotFound404} from "../404";
import {Header} from "../Header";
import {LastPlayedMatch} from "../LastPlayedMatch";
import { Footer } from '../Footer'

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <Header/>
    <Routes>
      <Route path="/" element={<LastPlayedMatch />}>
      </Route>
      <Route path="match">
        <Route path=":matchId" element={<Match/>}/>
      </Route>
      <Route path="*" element={<NotFound404/>}/>
    </Routes>
    <ReactQueryDevtools initialIsOpen={false}/>
    <Footer />
  </QueryClientProvider>
)
