import styled from 'styled-components'

export const TableValue = styled.div`
  color: #FFF;
  font-family: SF Pro Rounded;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
