import React from 'react'
import {
  AccCell1Wrapper,
  AccCellWrapper,
  GunHitsWrapperP1,
  GunHitsWrapperP2, GunKillsWrapperP1, GunKillsWrapperP2,
  GunShotsWrapperP1,
  GunShotsWrapperP2
} from '../styles'
import { GunIcon } from './GunIcon'

const getAcc = player => {
  const shots = player?.shots
  const hits = player?.hits

  if (!hits || !shots) {
    return '0.00%'
  }

  const rounded = Number(hits / shots * 100)?.toFixed(2)

  return `${String(rounded).replace(/\.00$/,'')}%`
}

export const GunRow = ({ p1, p2, icon }) => {
  const gun = icon?.toLowerCase()
  const p1acc = getAcc(p1)
  const p2acc = getAcc(p2)

  return (
    <>
      <GunShotsWrapperP1 gun={gun}>{ p1?.shots || 0 }</GunShotsWrapperP1>
      <GunHitsWrapperP1 gun={gun}>{ p1?.hits || 0}</GunHitsWrapperP1>
      <GunKillsWrapperP1 gun={gun}>{ p1?.kills || 0}</GunKillsWrapperP1>
      <AccCellWrapper gun={gun}>{ p1acc }</AccCellWrapper>
      <GunIcon icon={icon} />
      <AccCell1Wrapper gun={gun}>{ p2acc }</AccCell1Wrapper>
      <GunKillsWrapperP2 gun={gun}>{ p2?.kills || 0}</GunKillsWrapperP2>
      <GunHitsWrapperP2 gun={gun}>{ p2?.hits || 0}</GunHitsWrapperP2>
      <GunShotsWrapperP2 gun={gun}>{ p2?.shots || 0}</GunShotsWrapperP2>
    </>
  )
}