import superagent from 'superagent'
import { useQuery } from '@tanstack/react-query'

import {API_URL} from "../data";


const getDuel = async (id) => (await superagent.get(`${ API_URL }/api/matches/duels/get/${id}`)).body.data

export const useGetDuel = (id) => useQuery({
  queryKey: ['getDuel', id],
  queryFn: () => getDuel(id),
  staleTime: Infinity
});
