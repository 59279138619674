import React from 'react'
import { TdmAPI } from './api'
import { useParams } from 'react-router'
import { TDMComponent } from './TDMComponent'
import { NextPrevMatchAPI } from '../Match/NextPrevMatch/api'


export const TDM = () => {
  const { matchId } = useParams()

  const { data, isLoading, isError, error } = TdmAPI.getTdm(matchId)

  const nextMatchData = NextPrevMatchAPI.getNextMatch(matchId)
  const prevMatchData = NextPrevMatchAPI.getPreviousMatch(matchId)

  const nextStatus = nextMatchData.status
  const nextData = nextMatchData.data
  const prevStatus = prevMatchData.status
  const prevData = prevMatchData.data

  return (
    <TDMComponent
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      nextStatus={nextStatus}
      nextData={nextData}
      prevStatus={prevStatus}
      prevData={prevData}
    />
  )
}
