import superagent from 'superagent'
import { useQuery } from '@tanstack/react-query'

import {API_URL} from "../data";

const getMatch = async (matchId) =>  (await superagent.get(`${API_URL}/api/matches/get/${matchId}`)).body.data

export const useGetMatch = (matchId) => useQuery(
  { queryKey: ['getMatch', matchId],
    queryFn: () => getMatch(matchId),}
)
