import { Link } from 'react-router-dom'
import { NextPrevMatchAPI } from './api'
import { EmptyWrapper, NextPrevMatchWrapper } from './styles'
import { useParams } from 'react-router'
import { useGetMatch } from '../api'

export const NextMatch = ({ matchId }) => {
  const {
    status,
    data,
    error
  } = NextPrevMatchAPI.getNextMatch(matchId)

  if (status === 'loading' || status === 'error') {
    return <EmptyWrapper/>
  }
  const id = data?.nextMatchId
  if (id === undefined || id === null) {
    return <EmptyWrapper/>
  }

  return (
    <div>
      <Link to={`/match/${id}`}>next match ></Link>
    </div>
  )
}

export const PrevMatch = ({ matchId }) => {
  const {
    status,
    data,
    error
  } = NextPrevMatchAPI.getPreviousMatch(matchId)

  if (status === 'loading' || status === 'error') {
    return <EmptyWrapper/>
  }

  const id = data?.previousMatchId
  if (id === undefined || id === null) {
    return <EmptyWrapper/>
  }

  return (
    <div>
      <Link to={`/match/${id}`}>{'< prev match'}</Link>
    </div>
  )
}

export const NextPrevMatch = () => {
  const {matchId} = useParams()

  const { status, data, error } = useGetMatch(matchId)

  return (
    <NextPrevMatchWrapper>
      <PrevMatch matchId={matchId}/>
      <NextMatch matchId={matchId}/>
    </NextPrevMatchWrapper>
  )
}
