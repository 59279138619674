import React, {useEffect} from 'react'
import { useNavigate } from 'react-router'

import {
  MatchInfoTempererServerWrapper,
} from './styles'
import { useLastMatch } from './api'


export const LastPlayedMatch = () => {
  const navigate = useNavigate()
  const { status, data, error } = useLastMatch()

  useEffect(() => {
    if (status === 'success') {
      const matchId= data?.matchId
      if (!matchId) {
        return
      }
      navigate(`./match/${matchId}`)
    }
  }, [status])

  if (status === 'loading' || !data) {
    return <MatchInfoTempererServerWrapper>Loading...</MatchInfoTempererServerWrapper>
  }

  if (status === 'error') {
    return <div>Error: { error.message }</div>
  }

  return <></>
}
