import styled from 'styled-components'

export const MatchInfoTempererServerWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 9px;
  position: relative;
`
