import React from 'react'
import {useGetDuel} from '../api'
import { MatchStatsWrapper, } from '../styles'
import { GunRows } from './GunRows'
import { StatsTopLine } from './StatsTopLine'
import { useParams } from 'react-router'

export const MatchStats = () => {
  const {matchId} = useParams()
  const { data, status, error } = useGetDuel(matchId)
  const weapons = data.players.map(q => q.weapons)

  return (
    <MatchStatsWrapper>
      <StatsTopLine data={ data }/>
      <GunRows weapons={ weapons }/>
    </MatchStatsWrapper>
  )
}
