import superagent from 'superagent'
import { API_URL } from '../data'

export class API {
  static routes = {
    duels: {
      getById: '/api/matches/duels/get/'
    },
    matches: {
      getLastMatch: '/api/matches/get/lastMatch',
      nextMatch: '/api/matches/nextMatch',
      prevMatch: '/api/matches/previousMatch',
    },
    tdms: {
      getById: '/api/matches/tdms/get'
    }
  }

  static getFullURL(route) {
    return `${API_URL}${route}`
  }

  static async getRouteByID(route, id) {
    const url = `${API.getFullURL(route)}/${id}`

    return (await superagent.get(url)).body.data
  }

  static async get(route) {
    const url = API.getFullURL(route)

    return (await superagent.get(url)).body.data
  }
}
