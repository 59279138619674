import { SmallWrapper } from './SmallWrapper'

export const RocketLauncherIcon = ({ small }) => (
  <SmallWrapper small={small} width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_90)">
      <path d="M6.29849 12.7071L8.38265 10.2761C7.11868 9.04009 5.65747 8.0539 4.46417 6.73897C4.77154 6.57461 5.08054 6.55488 5.36325 6.45791C5.55063 6.39545 5.6624 6.53351 5.78567 6.62227C6.8672 7.35863 7.81723 8.26593 8.84123 9.06967C8.95464 9.15843 9.07134 9.2439 9.21598 9.35567L11.1489 6.89019C11.5056 7.17783 11.8425 7.4441 12.1729 7.71201C17.0053 11.6699 21.7702 15.7068 26.5434 19.7337C27.7876 20.7856 28.8725 21.9822 29.5513 23.501C30.0509 24.5783 30.1357 25.8019 29.7896 26.9379C29.6055 27.5641 29.0779 27.8402 28.4895 27.9602C27.0809 28.2495 25.7281 28.0144 24.4346 27.426C22.8468 26.7094 21.55 25.5917 20.2383 24.4904C16.5226 21.3565 12.8024 18.2265 9.07791 15.1002C8.15254 14.3162 7.24195 13.519 6.29849 12.7071Z" fill="#FE3300"/>
      <path d="M15.1792 9.42636C14.2538 8.5881 13.2725 7.86325 12.3258 7.09566C11.689 6.58483 11.0887 6.03012 10.5293 5.43557C10.3625 5.26916 10.262 5.04772 10.2466 4.81262C10.1118 3.22156 10.2696 1.64366 10.4405 0C10.8629 0.351742 11.2525 0.668968 11.6338 0.996055C12.9586 2.14661 14.3146 3.24951 15.6854 4.33761C15.8317 4.45431 15.9616 4.55128 15.9254 4.78468C15.6937 6.26397 15.4783 7.7597 15.2548 9.24556C15.235 9.30794 15.2097 9.36844 15.1792 9.42636Z" fill="#FE3300"/>
      <path d="M9.51348 16.1522C8.10158 16.7176 6.75542 17.3521 5.28435 17.6315C5.16085 17.6503 5.03481 17.6438 4.91388 17.6125C4.79295 17.5811 4.67965 17.5255 4.58087 17.449C3.238 16.573 2.08251 15.4766 0.90401 14.3984C0.621302 14.1469 0.328731 13.8954 0 13.5947C0.706772 13.3498 1.36423 13.1262 2.01841 12.8977C2.73997 12.6479 3.46154 12.4047 4.17982 12.14C4.27397 12.095 4.37957 12.0798 4.48261 12.0962C4.58565 12.1126 4.68125 12.16 4.75674 12.2321C6.11549 13.3761 7.48521 14.5107 8.86588 15.6361C9.05983 15.8037 9.30638 15.9171 9.51348 16.1522Z" fill="#FE3300"/>
    </g>
    <defs>
      <clipPath id="clip0_7_90">
        <rect width="30" height="28.0835" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)