import styled from 'styled-components';

export const HeaderQ4StatsLogoWrapper = styled.img`
  width: 692px;
  height: 127px;
`;

export const HeaderQ4StatsTextWrapper = styled.img`
  width: 239px;
  height: 31px;
`;

export const HeaderQ4StatsContainer = styled.div`
  margin-left: 41px;
  margin-right: 60px;
  padding-top: 53px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StatsQuake4NetWrapper = styled.div`
  font-family: 'Town 21 Outline';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-align: right;
  color: #FFFFFF;
  text-shadow: 0px 0px 10px #D7F0FF, 0px 0px 10px #D7F0FF;
`
