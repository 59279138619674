import styled from 'styled-components'
import { rgba } from 'polished'

export const LastPlayedMatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
`

export const LastPlayedMatchHeaderWrapper = styled.div`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
`

export const FindMatchButtonWrapper = styled.button`
  width: 170px;
  height: 50px;
  background: ${ rgba(255, 255, 255, 0.1) } !important;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
`

export const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const MatchInfoDateWrapper = styled.div`
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);

  &:first-child {
    margin-right: 25px;
  }
`

export const MatchInfoTempererServerWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 9px;
  position: relative;
`

export const MatchInfoServerAndDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const MatchGeneralInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
`

export const BottomLineOfGeneralInfoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
`

export const MatchTypeWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: right;
  color: #FFFFFF;
  margin-right: 42px;
`

export const MapNameWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: right;
  color: #FFFFFF;
`

export const BottomRightWrapper = styled.div`
  display: flex;
`

export const MatchStatsWrapper = styled.div`
  margin-top: 40px;

  display: grid;
  grid-template-columns: 
          [shots] 51px
          [gap1] 78px
          [hits] 34px
          [gap2] 77px
          [kills] 36px 
          [gap3] 59px
          [accuracy] 85px
          [gap4] 47px
          [weapon-icon] 30px 
          [gap5] 45px
          [accuracy1] 85px
          [gap6] 59px
          [kills1] 36px
          [gap7] 78px
          [hits1] 34px
          [gap8] 77px
          [shots1] 51px;
  grid-template-rows: 
          [overall-data] 100px 
          [column-names] 50px 
          [mg-stat] 50px 
          [shotgun-stat] 50px 
          [hyperblaster-stat] 50px 
          [rocket-stat] 50px 
          [railgun-stat] 50px 
          [lg-stat] 50px 
          [gl-stat] 50px 
          [nailgun-stat] 50px 
          [awards] 25px;
  color: white;

  column-gap: 0;
`

export const P1DmgWrapper = styled.div`
  grid-column-start: shots;
  grid-column-end: hits;
`

export const P2DmgWrapper = styled.div`
  grid-column-start: shots1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const TableHeadersCellWrapper = styled.div`
  grid-row-start: column-names;
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #FFFFFF;
`

export const GunCellWrapper = styled.div`
  grid-row-start: [${ props => props?.gun }-stat];

  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #FFFFFF;
  align-self: center;
`

export const AccCellWrapper = styled.div`
  grid-row-start: [${ props => props?.gun }-stat];
  grid-column-start: accuracy;
  grid-column-end: gap4;


  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #FFFFFF;
  align-self: center;
`

export const AccCell1Wrapper = styled.div`
  grid-row-start: [${ props => props?.gun }-stat];
  grid-column-start: accuracy1;
  grid-column-end: gap6;


  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #FFFFFF;
  align-self: center;
`


export const GunShotsWrapperP1 = styled(GunCellWrapper)`
  color: rgba(255, 86, 120, 0.75);
  grid-column-start: shots;
  grid-column-end: gap1;
`

export const GunShotsWrapperP2 = styled(GunCellWrapper)`
  color: rgba(255, 86, 120, 0.75);
  grid-column-start: shots1;
`

export const GunHitsWrapperP1 = styled(GunCellWrapper)`
  color: rgba(100, 210, 255, 0.75);
  grid-column-start: hits;
  grid-column-end: gap2;
`

export const GunHitsWrapperP2 = styled(GunCellWrapper)`
  color: rgba(100, 210, 255, 0.75);
  grid-column-start: hits1;
  grid-column-end: gap8;
`

export const GunKillsWrapperP1 = styled(GunCellWrapper)`
  color: rgba(65, 255, 112, 0.75);
  grid-column-start: kills;
  grid-column-end: gap3;
`

export const GunKillsWrapperP2 = styled(GunCellWrapper)`
  color: rgba(65, 255, 112, 0.75);
  grid-column-start: kills1;
  grid-column-end: gap7;
`

export const GunIconWrapper = styled.div`
  grid-column-start: weapon-icon;
  grid-column-end: gap5;
  justify-self: center;
  align-self: center;
`

export const TableHeadersCellShotsWrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: shots;
  grid-column-end: gap1;
`

export const TableHeadersCellHitsWrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: hits;
  grid-column-end: gap2;
`

export const TableHeadersCellKillsWrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: kills;
  grid-column-end: gap3;
`

export const TableHeadersCellAccuracyWrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: accuracy;
  grid-column-end: gap4;
`

export const TableHeadersCellWeaponIconWrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: weapon-icon;
  grid-column-end: gap5;
`


export const TableHeadersCellAccuracy1Wrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: accuracy1;
  grid-column-end: gap6;
`

export const TableHeadersCellKills1Wrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: kills1;
  grid-column-end: gap7;
`

export const TableHeadersCellHits1Wrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: hits1;
  grid-column-end: gap8;
`

export const TableHeadersCellShots1Wrapper = styled(TableHeadersCellWrapper)`
  grid-column-start: shots1;
`

const ServerNameLetter = styled.span`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`

export const ServerNameLetterYellow = styled(ServerNameLetter)`
  color: #FFE600;
`

export const ServerNameLetterBlue = styled(ServerNameLetter)`
  color: #61D0FF;
`

export const ServerNameLetterWhite = styled(ServerNameLetter)`
  color: white;
`

export const ServerNameLetterRed = styled(ServerNameLetter)`
  color: #FF3636;
`

export const ServerNameLetterDarkBlue = styled(ServerNameLetter)`
  color: #518CFF;
`

export const ServerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 9px;
`
