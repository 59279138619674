import React from 'react'

export const RampageAwardIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_233)">
      <path
        d="M27.762 55.9999C11.9865 55.9751 -0.0116289 43.8189 8.4583e-06 27.8783C0.0116458 11.8574 12.175 -0.0520294 28.4707 -7.48102e-05C44.1404 0.0495182 56.0384 12.3049 56 28.3542C55.9627 44.074 43.7564 56.0247 27.762 55.9999ZM27.9948 12.8788C26.6739 9.82053 26.5657 6.56628 24.7363 3.75128C13.2863 5.25561 4.42219 14.324 3.96368 24.8118C6.02233 26.6125 9.24703 25.9926 11.4546 28.1475C8.86532 29.829 5.75234 29.4936 3.7321 31.4053C5.48701 42.9935 14.7794 51.9262 25.0982 51.9545C25.9338 49.495 26.7868 46.9846 27.7748 44.0858C29.9312 46.8512 29.3447 50.2483 31.4743 52.2769C43.1407 50.3794 52.1608 40.9059 51.9083 30.881L43.9635 28.1369C46.8321 26.1402 50.1452 26.616 52.2702 24.5851C50.5176 12.998 41.2776 4.1067 30.8645 4.0394C29.8975 7.01498 28.9839 9.82525 27.9959 12.8729L27.9948 12.8788Z"
        fill="#EC6D12"/>
      <path
        d="M28.0878 13.1067C37.3058 14.5968 40.9308 18.6942 41.8676 28.1605C42.2435 31.965 42.2435 31.965 44.4173 34.8745C43.5806 36.38 42.3529 37.5171 40.8505 38.542C38.7884 37.5844 36.7065 36.504 34.0787 37.4439C33.0023 39.443 34.8003 42.4032 32.2005 44.185C29.4762 41.1291 26.5994 40.7761 23.8007 44.3692C21.2497 42.5343 22.5764 39.8185 22.2459 37.6234C19.5076 36.2702 17.3303 37.6116 14.7771 38.7085L11.415 34.9512C13.725 32.9333 13.959 30.1608 14.2871 27.2443C15.2914 18.314 18.9688 14.5555 27.9156 13.1067H28.0878ZM25.644 27.569C23.5912 25.4943 22.0038 23.2083 19.2086 24.4387C16.6414 25.5711 16.2259 27.9515 17.1069 30.5823C20.4479 31.2424 22.8941 30.0356 25.6463 27.5713L25.644 27.569ZM39.0537 30.528C39.6193 27.6256 39.1829 25.2676 36.6669 24.3879C33.9275 23.435 32.1539 25.29 30.6155 27.798C32.986 29.9801 35.4706 31.3203 39.056 30.5304L39.0537 30.528ZM30.2093 35.6633C31.1403 32.6322 30.1081 30.704 27.9203 29.1559C25.7802 31.0204 24.4698 32.8235 26.1851 35.6633C27.3489 35.6633 27.5746 34.2817 28.0471 34.2711C28.5196 34.2605 28.6476 35.6656 30.2163 35.6656L30.2093 35.6633Z"
        fill="#F16A0A"/>
    </g>
    <defs>
      <clipPath id="clip0_7_233">
        <rect width="56" height="56" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)