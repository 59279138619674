import { Fragment } from 'react'
import { flexRender } from '@tanstack/react-table'
import { PickupsTable } from '../../PickupsTable'

export const TBody = ({
  table,
  type,
  players
}) => (
  <tbody>
  {
    table.getRowModel()
      .rows
      .map(row => (
        <Fragment key={`${type}-${row.id}`}>
          <tr onClick={row.getToggleExpandedHandler()}>
            {
              row.getVisibleCells()
                .map(cell => (
                  <td key={`${type}-${cell.id}`}>
                    {
                      flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    }
                  </td>
                ))
            }
          </tr>
          {
            row.getIsExpanded() && (
              <tr>
                <td colSpan={row.getVisibleCells().length}>
                  <PickupsTable players={players} row={row}/>
                  <div>sub table 2</div>
                </td>
              </tr>
            )
          }
        </Fragment>
      ))
  }
  </tbody>
)
