import { SmallWrapper } from './SmallWrapper'

export const HyperBlasterIcon = ({ small }) => (
  <SmallWrapper small={ small } width="30" height="30" viewBox="0 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_82)">
      <path
        d="M0 7.07426L17.0297 0.252296C20.1392 -0.874187 21.5987 2.01834 19.6195 3.55353L2.86477 17.2025L11.0453 6.13826L0 7.07426Z"
        fill="#0072FD"/>
      <path d="M2.86475 27.1529L23.7154 1.75719C27.3408 -2.41697 32.1028 2.53929 28.1053 5.9364L2.86475 27.1529Z"
            fill="#0072FD"/>
      <path
        d="M12.6588 26.7429L25.5332 10.6455C27.5515 7.816 30.9638 9.92137 29.7427 12.5692L22.7542 29.6077L23.5868 18.6797L12.6588 26.7429Z"
        fill="#0072FD"/>
    </g>
    <defs>
      <clipPath id="clip0_7_82">
        <rect width="30" height="29.6077" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)