import {
    TableHeadersCellAccuracy1Wrapper,
    TableHeadersCellAccuracyWrapper, TableHeadersCellHits1Wrapper,
    TableHeadersCellHitsWrapper, TableHeadersCellKills1Wrapper,
    TableHeadersCellKillsWrapper, TableHeadersCellShots1Wrapper,
    TableHeadersCellShotsWrapper, TableHeadersCellWeaponIconWrapper,
} from '../styles'
import React from 'react'

export const TableHeaders = () => (
  <>
    <TableHeadersCellShotsWrapper>shots</TableHeadersCellShotsWrapper>
    <TableHeadersCellHitsWrapper>hits</TableHeadersCellHitsWrapper>
    <TableHeadersCellKillsWrapper>kills</TableHeadersCellKillsWrapper>
    <TableHeadersCellAccuracyWrapper>accuracy</TableHeadersCellAccuracyWrapper>
    <TableHeadersCellWeaponIconWrapper></TableHeadersCellWeaponIconWrapper>
    <TableHeadersCellAccuracy1Wrapper>accuracy</TableHeadersCellAccuracy1Wrapper>
    <TableHeadersCellKills1Wrapper>kills</TableHeadersCellKills1Wrapper>
    <TableHeadersCellHits1Wrapper>hits</TableHeadersCellHits1Wrapper>
    <TableHeadersCellShots1Wrapper>shots</TableHeadersCellShots1Wrapper>
  </>
)