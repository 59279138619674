import React from 'react'

import { flexRender } from '@tanstack/react-table'

export const THead = ({ table, type }) => (
  <thead>
  {table.getHeaderGroups()
    .map(headerGroup => (
      <tr key={`${type}-${headerGroup.id}`}>
        {headerGroup.headers.map(header => (
          <th key={`${type}-${header.id}`} colSpan={header.colSpan}>
            {header.isPlaceholder ? null : (
              <div>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </div>
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>
)
