import styled from 'styled-components'

export const FooterImageWrapper = styled.img`
  width: 108px;
  height: 30px;
`

export const FooterWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 54px;
  margin-bottom: 54px;
`
