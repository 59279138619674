import React from 'react'
import {
  ServerNameLetterBlue, ServerNameLetterDarkBlue,
  ServerNameLetterRed,
  ServerNameLetterWhite,
  ServerNameLetterYellow,
  ServerNameWrapper
} from "./styles";

export const ServerName = ({ server }) => {
  const servers = {
    '185.96.163.168:28004': <><ServerNameLetterDarkBlue>Sweden</ServerNameLetterDarkBlue></>,
    '176.36.131.100:28004': <><ServerNameLetterYellow>Ukraine</ServerNameLetterYellow></>,
    '108.61.116.132:28004': <><ServerNameLetterDarkBlue>United Kingdom 1</ServerNameLetterDarkBlue></>,
    '108.61.98.66:28004': <><ServerNameLetterDarkBlue>Europe</ServerNameLetterDarkBlue></>
  }


  return (
    <ServerNameWrapper>
      <ServerNameLetterYellow>W</ServerNameLetterYellow>
      <ServerNameLetterBlue>w</ServerNameLetterBlue>
      <ServerNameLetterYellow>W</ServerNameLetterYellow>
      &nbsp;

      <ServerNameLetterWhite>Quake</ServerNameLetterWhite>
      <ServerNameLetterRed>4</ServerNameLetterRed>
      <ServerNameLetterWhite>.net</ServerNameLetterWhite>
      &nbsp;
      {servers[server]}
    </ServerNameWrapper>
  )
}