import React from 'react'
import { MachinegunIcon } from '../../components/Icons/Machinegun'
import { GunIconWrapper } from '../styles'
import { ShotgunIcon } from '../../components/Icons/Shotgun'
import { HyperBlasterIcon } from '../../components/Icons/HyperBlaster'
import { RocketLauncherIcon } from '../../components/Icons/RocketLauncher'
import { RailgunIcon } from '../../components/Icons/Railgun'
import { LightningGunIcon } from '../../components/Icons/LightningGun'
import { GrenadeLauncherIcon } from '../../components/Icons/GrenadeLauncher'
import { NailgunIcon } from '../../components/Icons/Nailgun'

const icons = {
  MG: <MachinegunIcon/>,
  Shotgun: <ShotgunIcon />,
  HyperBlaster: <HyperBlasterIcon />,
  Rocket: <RocketLauncherIcon />,
  Railgun: <RailgunIcon />,
  LG: <LightningGunIcon />,
  GL: <GrenadeLauncherIcon />,
  Nailgun: <NailgunIcon />
}

export const GunIcon = ({ icon }) => (
  <GunIconWrapper>
    { icons[icon] }
  </GunIconWrapper>
)