import {
  CountWrapper,
  MarineKillCountWrapper, StroggTextWrapper,
  TextAndImageWrapper,
  TextWrapper
} from './killCountStyles'
import React from 'react'
import { StroggLogo } from './StroggLogo'

export const StroggKillCount = ({ killCount }) => {
  return (
    <MarineKillCountWrapper>
      <TextAndImageWrapper>
        <StroggLogo />
        <StroggTextWrapper>Strogg</StroggTextWrapper>
      </TextAndImageWrapper>
      <CountWrapper>{ killCount }</CountWrapper>
    </MarineKillCountWrapper>
  )
}
