import React from 'react';
import { HeaderQ4StatsContainer, HeaderQ4StatsLogoWrapper, StatsQuake4NetWrapper } from './styles';
import {Link} from "react-router-dom";

export const Header = () => (
  <HeaderQ4StatsContainer>
    <Link to="/">
      <HeaderQ4StatsLogoWrapper src={'/images/q4statslogo.png'} />
    </Link>
    <Link to="/">
      <StatsQuake4NetWrapper>{"STATS.QUAKE4.NET"}</StatsQuake4NetWrapper>
    </Link>
  </HeaderQ4StatsContainer>
);
