import { TableValue } from '../styles'
import { clearRBGcolors } from '../../../../Duel/MatchStats/StatsTopLine'
import { SkullIcon } from '../../../../components/Icons/SkullIcon'
import { TopGuns } from './TopGuns'
import {
  DeathRowWrapper,
  KillsRowWrapper, NameWrapper, NetWrapper, ScoreWrapper,
  SuicidesWrapper,
  TeamKillsWrapper,
  TopGunsWrapper
} from './styles'

export class RowsComponents {
  static getNameRow = ({ row }) => (
    <TableValue>
      <NameWrapper>
        {clearRBGcolors(row.original.name)}
      </NameWrapper>
    </TableValue>
  )

  static getKillsRow = ({ row }) => (
    <KillsRowWrapper>
      <TableValue>
        {row.original.kills}
      </TableValue>
      {row.original.teamKills !== 0 && (
        <TeamKillsWrapper>
          {row.original.teamKills}
        </TeamKillsWrapper>
      )}
    </KillsRowWrapper>
  )

  static getDeathsRow = ({ row }) => (
    <DeathRowWrapper>
      <div>
        <TableValue>{row.original.deaths}</TableValue>
      </div>
      {row.original.suicides !== 0 && (
        <SuicidesWrapper>
          <SkullIcon/>
          <TableValue>{row.original.suicides}</TableValue>
        </SuicidesWrapper>
      )}
    </DeathRowWrapper>
  )

  static getScoreRow = ({ row }) => (
    <TableValue>
      <ScoreWrapper>
        {row.original.score}
      </ScoreWrapper>
    </TableValue>
  )

  static getNetRow = ({ row }) => (
    <TableValue>
      <NetWrapper>
        {row.original.net}
      </NetWrapper>
    </TableValue>
  )

  static getDamageGivenRow = ({ row }) => (
    <TableValue><NetWrapper>{row.original.damageGiven}</NetWrapper></TableValue>
  )

  static getDamageTakenRow = ({ row }) => (
    <TableValue><NetWrapper>{row.original.damageTaken}</NetWrapper></TableValue>
  )

  static getBWRow = ({ row }) => (
    <TopGunsWrapper>
      <TopGuns row={row}/>
    </TopGunsWrapper>
  )
}
