import styled from 'styled-components'

const MarineLogoWrapper = styled.svg`
  margin-top: 5px;
`

export const MarineLogo = () =>  (
  <MarineLogoWrapper width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.71289 21.1404L2.71347 20.557H10.5664L15.6361 3.79208L15.6355 14.6003L12.9521 23.3639L15.6361 30.2958L2.71289 21.1404Z" fill="#91DF7E" />
      <path d="M17.3621 3.79196L17.361 14.6002L20.075 23.3638L17.3615 30.2957L30.2865 21.1403V20.5557H22.2685L17.3621 3.79196Z" fill="#91DF7E" />
      <path d="M16.4988 2.11963L31.2693 12.5637V21.6257L16.5052 31.9641L1.73125 21.5189V12.5331L16.4988 2.11963ZM16.5 0L0 11.6358V22.4151L16.5 34.0809L33 22.5265V11.6669L16.5 0Z" fill="#91DF7E" />
      <path d="M16.7713 29.7539H16.2294V30.2958H16.7713V29.7539Z" fill="#91DF7E" />
      <path d="M16.9915 29.2074H16.007L16.0087 28.6719H16.9926L16.9915 29.2074Z" fill="#91DF7E" />
      <path d="M17.312 28.1282L15.6881 28.13V27.5892L17.312 27.5881V28.1282Z" fill="#91DF7E" />
      <path d="M17.8543 27.0491H15.1472L15.1455 26.4876H17.8532L17.8543 27.0491Z" fill="#91DF7E" />
      <path d="M18.3953 25.4252H14.6056V25.9671H18.3953V25.4252Z" fill="#91DF7E" />
      <path d="M13.9789 23.3638L16.5002 15.2039L19.1351 23.3638L18.6764 24.3691H14.3436L13.9789 23.3638Z" fill="#91DF7E" />
  </MarineLogoWrapper>
);
