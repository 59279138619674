export const maps ={
  'jumpimjackstrap': 'JumpinJackStrap',
  'ktonq4dm2': 'Torment by killat0n',
  'ktonq4dm4': 'Ravage by killat0n',
  'l4ctf2_beta4': 'RUINER [beta4]',
  'l4dm1_1': 'GALANG [1.1] by LUKIN',
  'l4dm1_2': 'GALANG [1.2 beta]',
  'l4dm2_1': 'MONSOON [1.1] by LUKIN',
  'l4dm3_1': 'RUINER [1.1] by LUKIN',
  'l4dm4': 'A MAN CALLED SUN by LUKIN',
  'l4dm5': 'SPIRAL by LUKIN',
  'l4dm5_tmp': '[TMP] SPIRAL by LUKIN',
  'ppplq4dm1b3_1b': 'MATROSKA [beta 3.1b] by pppL',
  'q4aero_QL_beta': 'Aerowalk [Revised] beta',
  'q4amphi': 'amphi',
  'q4ctf1': 'HEARTLESS',
  'q4ctf1_norg_perf': 'Heartless noRG [PERFIX]',
  'q4ctf2': 'DEATH BEFORE DISHONOR',
  'q4ctf2_perfix': 'Death before Dishonor [PERFIX]',
  'q4ctf3': 'SPEED TRAP',
  'q4ctf4': 'RELATIVITY',
  'q4ctf5': 'XAERO GRAVITY',
  'q4ctf6': 'MIND THE GAP',
  'q4ctf7': 'TREMORS',
  'q4ctf8': 'DOUBLE EDGED',
  'q4dm1': 'FRAGGING YARD',
  'q4dm1tmp': '[TMP] FRAGGING YARD',
  'q4dm2': 'SANDSTORM',
  'q4dm2tmp': '[TMP] SANDSTORM',
  'q4dm3': 'THE LOST FLEET',
  'q4dm3tmp': '[TMP] THE LOST FLEET',
  'q4dm4': 'BLOODWORK',
  'q4dm4tmp': '[TMP] BLOODWORK',
  'q4dm5': 'THE ROSE',
  'q4dm5tmp': '[TMP] THE ROSE',
  'q4dm6': 'NO DOCTORS',
  'q4dm6tmp': '[TMP] NO DOCTORS',
  'q4dm7': 'OVER THE EDGE',
  'q4dm7tmp': '[TMP] OVER THE EDGE',
  'q4dm8': 'THE LONGEST DAY',
  'q4dm9': 'CAMPGROUNDS REDUX',
  'q4dm10': 'OUTPATIENT',
  'q4dm11': 'SKELETON CREW',
  'q4dm11v1': 'FRAGGING YARD 1v1',
  'q4dm11v1tmp': '[TMP] FRAGGING YARD 1v1',
  'q4dz1': 'HEARTLESS',
  'q4dz2': 'DEATH BEFORE DISHONOR',
  'q4dz3': 'SPEED TRAP',
  'q4dz4': 'THE ROSE',
  'q4tourney1': 'RAILED',
  'q4wcp9fixed1': 'Spider Crossings [PERFIX]',
  'q4wcp14_perf': 'Camper Crossings [PERFIX]',
  'q4wxs1': 'q4wxs1',
  'q4xctf6': 'CAVERNOUS CRYONICS',
  'q4xdm10': 'CENTRAL INDUSTRIAL',
  'q4xdm11': 'WARFORGED',
  'q4xdm13': 'STROYENT RED',
  'q4xdm14': 'RETROPHOBOPOLIS',
  'q4xdm15': 'FIREWALL',
  'q4xtourney1': 'STROGGENOMENON',
  'q4xtourney2': 'VERTICON',
  'se4dm1': 'REPENT by SyncError',
  'swq4dm1': 'PHRANTIC',
  'tj_h0m3': 'TJ\'s H0m3 [Beta2]',
  'ts_q4dm3_fa': 'Rail Trouble by TymoN',
  'wvwq4dm1': 'PLACEBO EFFECT',
  'xtrafe': 'EKM XTRAFE MAP'
}

export const weaponsMap = {
  'Machinegun': 'MG',
  'Shotgun': 'Shotgun',
  'HyperBlaster': 'HyperBlaster',
  'Rocket Launcher': 'Rocket',
  'Railgun': 'Railgun',
  'Lightning Gun': 'LG',
  'Grenade Launcher': 'GL',
  'Nailgun': 'Nailgun'
}

export const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_PROD
