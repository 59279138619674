import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { THead } from './THead'
import { TBody } from './TBody'
import { columns, filterDataByType } from './table'

export const MainTable = ({
  data,
  type
}) => {
  const players = useMemo(() => filterDataByType(data, type), [data, type])

  const table = useReactTable({
    data: players,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <table>
      <THead table={table} type={type} />
      <TBody table={table} type={type} players={players} />
    </table>
  )
}
