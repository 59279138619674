import React from 'react'
import {pipe} from 'ramda'
import {GunRow} from './GunRow'

const reducedWeaponsData = weapons => weapons.reduce((acc, curr, index) => {
  const weaponsMap = {
    'Machinegun': 'MG',
    'Shotgun': 'Shotgun',
    'HyperBlaster': 'HyperBlaster',
    'Grenade Launcher': 'GL',
    'Nailgun': 'Nailgun',
    'Rocket Launcher': 'Rocket',
    'Railgun': 'Railgun',
    'Lightning Gun': 'LG'
  }

  for (const weapon in curr) {
    acc[weaponsMap[weapon]][`p${index + 1}`] = {
      shots: curr[weapon].shots,
      hits: curr[weapon].hits,
      kills: curr[weapon].kills,
    }
  }
  return acc
}, {
  MG: {},
  Shotgun: {},
  HyperBlaster: {},
  GL: {},
  Nailgun: {},
  Rocket: {},
  Railgun: {},
  LG: {}
})

const reduceWeaponsDataToGunRow = weapons => {
  const result = []

  for (const weapon in weapons) {
    const gun = weapons[weapon]
    result.push({p1: gun?.p1 || null, p2: gun?.p2 || null, icon: weapon})
  }

  return result
}

const getGunsData = pipe(
  reducedWeaponsData,
  reduceWeaponsDataToGunRow
)

export const GunRows = ({weapons}) => {
  const guns = getGunsData(weapons)
  return (
    <>
      {
        guns.map((gun, index) => <GunRow key={ `${ gun }-${ index }` } p1={ gun.p1 } p2={ gun.p2 } icon={ gun.icon }/>)
      }
    </>
  )
}