import { TableValue } from '../styles'

export class HeaderComponents {
  static getNameHeader = () => (
    <TableValue>{'name'}</TableValue>
  )

  static getKillsHeader = () => (
    <TableValue>{'kills'}</TableValue>
  )

  static getDeathsHeader = () => (
    <TableValue>{'deaths'}</TableValue>
  )

  static getScoreHeader = () => (
    <TableValue>{'score'}</TableValue>
  )

  static getNetHeader = () => (
    <TableValue>{'net'}</TableValue>
  )

  static getDamageGivenHeader = () => (
    <TableValue>{'DG'}</TableValue>
  )

  static getDamageTakenHeader = () => (
    <TableValue>{'DT'}</TableValue>
  )

  static getBWHeader = () => (
    <TableValue>{'BW'}</TableValue>
  )
}
