import { API } from '../API'
import { useQuery } from '@tanstack/react-query'

export class TdmAPI {
  static async getTdmData(matchId) {
    return await API.getRouteByID(API.routes.tdms.getById, matchId)
  }

  static getTdm = (matchId) => useQuery(
    ['getTdm', matchId],
    () => TdmAPI.getTdmData(matchId),
    { staleTime: Infinity }
  )
}
