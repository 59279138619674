import React from 'react'
import {ExcellentAwardIcon} from './Icons/ExcellentAwardIcon'

import styled from 'styled-components'
import {ImpressiveAwardIcon} from './Icons/ImpressiveAwardIcon'
import {HumiliationAwardIcon} from './Icons/HumiliationAwardIcon'
import {ComboKillAwardIcon} from './Icons/ComboKillAwardIcon'
import {RampageAwardIcon} from './Icons/RampageAwardIcon'
import {useGetDuel} from '../../api'
import { useParams } from 'react-router'

const AwardListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AwardIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 35px;

  &:last-child {
    margin-right: 0;
  }
`

const BasicAwardNumberWrapper = styled.div`
  margin-top: 16px;
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
`

const WithNumberAwardNumberWrapper = styled(BasicAwardNumberWrapper)`
  color: #FFFFFF;
`


const getAwardData = (awardList, awardType) => {
  const awards = awardList.find(q => q?.name === awardType)
  return awards
}

const AwardNumber = ({awardType, playerNumber}) => {
  const {matchId} = useParams()

  const {data} = useGetDuel(matchId)
  const awards = data?.players?.map(q => q?.awards)
  const awardList = awards[playerNumber]
  const awardData = getAwardData(awardList, awardType)

  if (awardData) {
    return <WithNumberAwardNumberWrapper>{awardData.value}</WithNumberAwardNumberWrapper>
  } else {
    return <BasicAwardNumberWrapper>0</BasicAwardNumberWrapper>
  }
}

export const AwardList = ({playerNumber}) => {
  return (
    <AwardListWrapper>
      <AwardIconWrapper>
        <ExcellentAwardIcon/>
        <AwardNumber playerNumber={playerNumber} awardType={'excellent'}/>
      </AwardIconWrapper>
      <AwardIconWrapper>
        <ImpressiveAwardIcon/>
        <AwardNumber playerNumber={playerNumber} awardType={'impressive'}/>
      </AwardIconWrapper>
      <AwardIconWrapper>
        <HumiliationAwardIcon/>
        <AwardNumber playerNumber={playerNumber} awardType={'humiliation'}/>
      </AwardIconWrapper>
      <AwardIconWrapper>
        <ComboKillAwardIcon/>
        <AwardNumber playerNumber={playerNumber} awardType={'combo_kill'}/>
      </AwardIconWrapper>
      <AwardIconWrapper playerNumber={playerNumber}>
        <RampageAwardIcon/>
        <AwardNumber playerNumber={playerNumber} awardType={'rampage'}/>
      </AwardIconWrapper>
    </AwardListWrapper>
  )
}
