import { API } from '../../API'
import { useQuery } from '@tanstack/react-query'

export class NextPrevMatchAPI {
  static async getNextMatchData(matchId) {
    return (await API.getRouteByID(API.routes.matches.nextMatch, matchId))
  }

  static getNextMatch = (matchId)  => useQuery(
    {
      queryKey: ['getNextMatch', matchId],
      queryFn: () => NextPrevMatchAPI.getNextMatchData(matchId),
      staleTime: Infinity
    }
    )

  static async getPrevMatchData(matchId) {
    return (await API.getRouteByID(API.routes.matches.prevMatch, matchId))
  }

  static getPreviousMatch = (matchId) => useQuery(
    {
queryKey: ['getPreviousMatch', matchId],
      queryFn: () => NextPrevMatchAPI.getPrevMatchData(matchId),
      staleTime: Infinity
    }
  )
}
