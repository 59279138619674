import React from 'react';
import styled from 'styled-components'

const StroggLogoWrapper = styled.svg`
  margin-top: 3px;
`

export function StroggLogo() {
  return (
    <StroggLogoWrapper width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2435 32.6735C18.6035 34.2462 18.961 35.8074 19.372 37.6031C20.6554 36.9041 21.7483 36.3089 22.7742 35.75C22.7392 34.6648 21.5501 34.1117 22.0758 33.0444C22.2421 32.7073 22.4798 32.2433 23.4013 31.9107C24.3144 31.5813 24.1882 33.1826 25.2053 32.9558C26.0241 31.8636 26.646 30.6528 26.6798 29.212C25.5526 28.6952 24.3335 29.2305 23.3318 28.5894C23.4822 27.2258 23.5637 26.8428 25.7851 26.159C26.7193 25.829 26.5154 27.3016 27.4177 27.1315C27.7689 24.8788 28.3863 22.7193 27.6663 20.2028C25.3563 21.4531 24.1666 24.0211 21.4927 25.2389C21.3398 24.8897 20.9492 24.4098 21.3309 23.7006C22.6366 21.2753 24.41 18.4632 24.9332 17.8431C25.6309 17.0166 26.8073 18.2771 27.4885 17.218C26.8423 15.0202 26.7232 12.651 25.3601 10.4245C23.3745 14.469 21.4774 18.3332 19.4255 22.5134C18.5946 21.2791 18.9043 20.5138 19.126 19.7657C20.0226 16.7356 20.9957 13.726 21.8069 10.6736C22.2013 9.19141 22.7615 8.00998 24.6254 7.7468C23.2865 5.12458 22.2211 2.5183 20.3814 0.00695801C20.0551 8.15591 17.2551 16.1596 16.442 23.366C18.3722 24.1976 19.727 25.8525 19.7805 27.8961C19.8557 30.756 18.6749 31.7476 18.2428 32.6735H18.2435Z" fill="#FDB447" />
      <path d="M8.66146 37.6331C7.33219 36.9156 6.29478 36.3555 5.09232 35.7061C5.63843 34.3992 6.82878 33.061 5.08212 32.014C3.98672 31.5871 3.75221 32.7424 2.92954 33.0922C1.96159 32.0134 1.55121 30.7064 1.1899 29.1586C2.38407 29.0184 3.461 28.8916 4.65071 28.752C5.2854 26.5472 3.56041 26.3261 2.60456 26.0367C1.56459 25.7226 1.36896 27.0347 0.6406 27.2405C0.217477 25.0325 -0.345201 22.855 0.276103 20.3316C2.59818 21.2811 3.65854 23.6356 5.71107 24.6514C6.40141 23.8111 5.59085 21.6519 3.27939 18.1739C2.44525 17.1187 1.36386 18.0599 0.541829 17.4188C1 15.142 1.37151 12.8403 2.59309 10.2722C4.62268 14.4493 6.48786 18.2886 8.44226 22.3127C9.43124 21.571 9.18973 20.8446 9.01513 20.2373C8.058 16.9065 7.03078 13.5954 6.07684 10.2633C5.71489 8.99838 5.2032 7.95523 3.51134 7.9291C4.40538 5.07238 5.90479 2.83251 7.59983 0C8.05354 8.31272 10.6573 15.6218 11.6985 23.3737C9.40384 24.2576 8.17972 26.1502 8.13702 28.304C8.16187 30.5044 9.02851 31.3226 9.79128 32.6742C9.44208 34.266 9.08586 35.6953 8.66083 37.6338L8.66146 37.6331Z" fill="#FDB447" />
      <path d="M14.7102 28.6761C15.7833 28.6761 16.9533 28.664 18.064 28.664C16.1173 31.2671 13.8481 34.3093 11.6369 37.2954C12.1874 34.5973 12.5341 32.5499 12.9285 30.542C11.9568 30.542 11.2316 30.5292 10.2126 30.5292C10.8798 28.7246 11.5948 26.9365 12.3346 25.2389H17.718" fill="#FDB447" />
    </StroggLogoWrapper>
  );
}
