import { SmallWrapper } from './SmallWrapper'

export const NailgunIcon = ({ small }) => (
  <SmallWrapper small={small} width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_73)">
      <path d="M3.28516 1.11938H25.5846V5.59855H14.4341L3.28516 1.11938Z" fill="#99CCCC"/>
      <path d="M30.0015 0H27.7612V6.68688H30.0015V0Z" fill="#99CCCC"/>
      <path d="M0 11.1163H22.301V15.5954H11.1505L0 11.1163Z" fill="#99CCCC"/>
      <path d="M26.7164 9.99536H24.4761V16.6822H26.7164V9.99536Z" fill="#99CCCC"/>
      <path d="M3.28516 21.1443H25.5846V25.6235H14.4341L3.28516 21.1443Z" fill="#99CCCC"/>
      <path d="M30.0015 20.0249H27.7612V26.7118H30.0015V20.0249Z" fill="#99CCCC"/>
    </g>
    <defs>
      <clipPath id="clip0_7_73">
        <rect width="30" height="26.7118" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)