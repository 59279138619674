import React from 'react'
import dayjs from 'dayjs'


import {
  BottomLineOfGeneralInfoWrapper,
  BottomRightWrapper,
  LastPlayedMatchWrapper,
  MapNameWrapper,
  MatchGeneralInfoWrapper,
  MatchInfoDateWrapper,
  MatchInfoServerAndDateWrapper,
  MatchInfoTempererServerWrapper,
  MatchTypeWrapper
} from './styles'
import { getMapName } from '../helpers'
import { MatchStats } from './MatchStats'
import {useGetDuel} from './api'
import { TopLine } from './LastPlayedHeader'
import { Awards } from './MatchStats/Awards/Awards'
import {ServerName} from "./ServerName";
import { NextPrevMatch } from '../Match/NextPrevMatch'
import { useParams } from 'react-router'

const formatDate = date => dayjs(date).format('D MMMM YYYY HH:mm:ss')

export const MatchGeneralInfo = ({ date, matchId, server, type, map }) => (
  <MatchGeneralInfoWrapper>
    <MatchInfoServerAndDateWrapper>
      <MatchInfoDateWrapper>{ formatDate(date) }</MatchInfoDateWrapper>
      <MatchInfoDateWrapper>Match ID: { matchId }</MatchInfoDateWrapper>
    </MatchInfoServerAndDateWrapper>
    <BottomLineOfGeneralInfoWrapper>
      <ServerName server={server} />
      <BottomRightWrapper>
        <MatchTypeWrapper>
          { type }
        </MatchTypeWrapper>
        <MapNameWrapper>{ getMapName(map) }</MapNameWrapper>
      </BottomRightWrapper>
    </BottomLineOfGeneralInfoWrapper>
  </MatchGeneralInfoWrapper>
)

export const Duel = () => {
  const {matchId} = useParams()
  console.log('duel', matchId)

  const { status, data, error } = useGetDuel(matchId)

  console.log('duel', status, data, error)

  if (status === 'loading' || !data) {
    return <MatchInfoTempererServerWrapper>Loading...</MatchInfoTempererServerWrapper>
  }

  if (status === 'error') {
    return <div>Error: { error.message }</div>
  }

  return (
    <LastPlayedMatchWrapper>
      <TopLine/>
      <MatchGeneralInfo
        date={ data.date }
        matchId={ data.matchId }
        server={ data.server }
        type={ 'Duel' }
        map={ data.map }
      />
      <MatchStats/>
      <Awards />
      <NextPrevMatch matchId={ data.matchId } />
    </LastPlayedMatchWrapper>
  )
}
