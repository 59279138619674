import styled from 'styled-components'

export const TextWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #91DF7E;
  
  margin-left: 14px;
`

export const CountWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: right;
  color: #FFFFFF;
`

export const TextAndImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const MarineKillCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
`

export const StroggTextWrapper  = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #FDB447;

  margin-left: 14px;
`
