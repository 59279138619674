import styled from 'styled-components'

export const EmptyWrapper = styled.div`
  width: 20px;
`

export const NextPrevMatchWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  font-family: 'SF Pro Rounded';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  
  & a {
    color: rgba(148, 203, 255, 0.75);
    text-decoration-line: underline;
    
  }
`
