import { SmallWrapper } from './SmallWrapper'

export const LightningGunIcon = ({ small }) => (
  <SmallWrapper small={small} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_86)">
      <path d="M29.9984 0.70572V0H23.896C23.896 0 18.8222 7.76753 16.5159 11.2592C16.336 9.56027 16.1776 8.05812 15.9931 6.30996L8.0411 17.4093C7.85967 16.1793 7.709 15.1384 7.51219 13.7915C5.47652 18.1073 -0.0108689 29.691 -0.000106261 29.8555C-0.000106261 29.8555 7.77972 20.5089 8.35783 20.1138C8.4962 21.5314 8.64073 22.9505 8.81908 24.6433L16.1653 14.8293C16.3314 16.4437 16.4728 17.8844 16.6589 19.6233L26.2268 8.96986C26.542 11.0301 26.8418 12.9828 27.1493 15.0308C27.8289 14.3819 29.9476 12.3708 30.003 12.3263C29.9999 8.36716 29.9984 4.66328 29.9984 0.70572Z" fill="#FFFEBA"/>
    </g>
    <defs>
      <clipPath id="clip0_7_86">
        <rect width="30" height="29.8555" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)