import { MatchInfoTempererServerWrapper } from '../Duel/styles'
import React from 'react'
import { TDMWrapper } from './styles'
import { TopLine } from '../Duel/LastPlayedHeader'
import { MatchGeneralInfo } from '../Duel'
import { TDMStats } from './Stats'
import { NextPrevMatchComponent } from '../Match/NextPrevMatch/component'

export const TDMComponent = ({ isLoading, isError, error, data, nextStatus, nextData, prevStatus, prevData }) => {
  if (isLoading) {
    return <MatchInfoTempererServerWrapper>Loading...</MatchInfoTempererServerWrapper>
  }

  if (isError) {
    return <div>Error: { error }</div>
  }

  return (
    <TDMWrapper>
      <TopLine />
      <MatchGeneralInfo
        date={ data.date }
        matchId={ data.matchId }
        server={ data.server }
        type={ 'Duel' }
        map={ data.map }
      />
      <TDMStats data={data} />
      <NextPrevMatchComponent nextStatus={nextStatus} nextData={nextData} prevStatus={prevStatus} prevData={prevData}/>
    </TDMWrapper>
  )
}
