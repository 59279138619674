import styled from 'styled-components'

export const TDMWrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  
  width: 960px;
`
