import { SmallWrapper } from './SmallWrapper'

export const MachinegunIcon = ({ small }) => (
  <SmallWrapper width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_68)">
      <path
        d="M2.43669 25.6117V19.3839H0V14.3866H2.43669V8.15886H0V3.19207H2.43669V0H12.4617V3.19207H19.4022V8.15886H12.4617V14.3866H19.4022V19.3839H12.4617V25.6117H19.4022V30.6617H12.4617V34.0041H2.43669V30.6617H0V25.6117H2.43669Z"
        fill="#FEFE00"/>
      <path
        d="M21.0551 8.20558V3.15756C22.9516 4.0368 24.4867 4.59115 26.8422 5.68157C26.5132 5.81153 21.384 8.06547 21.0551 8.20558Z"
        fill="#FEFE00"/>
      <path
        d="M21.0551 19.4204V14.3745C22.9516 15.2517 24.4867 15.7959 26.8422 16.8964C26.5132 17.0284 21.384 19.2905 21.0551 19.4204Z"
        fill="#FEFE00"/>
      <path
        d="M21.0551 30.6617V25.6117C22.9516 26.4889 24.4867 27.0433 26.8422 28.1337C26.5132 28.2657 21.384 30.5176 21.0551 30.6617Z"
        fill="#FEFE00"/>
    </g>
    <defs>
      <clipPath id="clip0_7_68">
        <rect width="26.8422" height="34" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)