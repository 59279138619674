import superagent from 'superagent'
import { useQuery } from '@tanstack/react-query'

import {API_URL} from "../data";

const getLastMatch = async () =>  (await superagent.get(`${API_URL}/api/matches/duels/lastMatch`)).body.lastMatch

export const useLastMatch = () => useQuery(
  {
    queryKey: ['lastMatch'],
    queryFn: getLastMatch,
  },
)
