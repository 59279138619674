import React from 'react'

import { P1DmgWrapper, P2DmgWrapper } from '../styles'
import { weaponsMap } from '../../data'
import { MachinegunIcon } from '../../components/Icons/Machinegun'
import { ShotgunIcon } from '../../components/Icons/Shotgun'
import { HyperBlasterIcon } from '../../components/Icons/HyperBlaster'
import { RocketLauncherIcon } from '../../components/Icons/RocketLauncher'
import { RailgunIcon } from '../../components/Icons/Railgun'
import { LightningGunIcon } from '../../components/Icons/LightningGun'
import { GrenadeLauncherIcon } from '../../components/Icons/GrenadeLauncher'
import { NailgunIcon } from '../../components/Icons/Nailgun'
import { SmallGunIconWrapper, TopGunsDamageWrapper, TopGunsIconsWrapper } from './styles'

const icons = {
  MG: <MachinegunIcon small/>,
  Shotgun: <ShotgunIcon small/>,
  HyperBlaster: <HyperBlasterIcon small/>,
  Rocket: <RocketLauncherIcon small/>,
  Railgun: <RailgunIcon small/>,
  LG: <LightningGunIcon small/>,
  GL: <GrenadeLauncherIcon small/>,
  Nailgun: <NailgunIcon small/>
}


const getTopGuns = (data, playerNumber) => {
  const topGuns = []
  const weapons = data?.players?.[playerNumber]?.weapons

  for (const gun in weapons) {
    topGuns.push({ name: weaponsMap[gun], kills: weapons[gun].kills })
  }

  const sortedByKillsCount = topGuns.sort((a, b) => b.kills - a.kills)
  const filteredWith0Kills = sortedByKillsCount.filter(gun => gun.kills !== 0)

  return filteredWith0Kills.slice(0, 3)
}

export const TopGunsIcons = ({ topGuns }) => {
  return (
    <TopGunsIconsWrapper>
      {
        topGuns.map((gun, index) => (
            <SmallGunIconWrapper key={ `${ gun.name }-${ index }` }>
              { icons[gun.name] }
            </SmallGunIconWrapper>
          )
        )
      }
    </TopGunsIconsWrapper>
  )
}

export const DmgAndTopGuns = ({ damage, playerNumber, data }) => {
  const topGuns = getTopGuns(data, playerNumber)

  if (playerNumber === 0) {
    return (
      <P1DmgWrapper>
        <TopGunsIcons topGuns={ topGuns }/>
        <TopGunsDamageWrapper>
          { damage }
        </TopGunsDamageWrapper>
      </P1DmgWrapper>
    )
  }


  return (
    <P2DmgWrapper>
      <TopGunsIcons topGuns={ topGuns }/>
      <TopGunsDamageWrapper>
        { damage }
      </TopGunsDamageWrapper>
    </P2DmgWrapper>
  )
}
