import React from 'react'
import { DmgAndTopGuns } from './DmgAndTopGuns'
import { TableHeaders } from './TableHeaders'
import { P1KillsCountWrapper, P1NameWrapper, P2KillsCountWrapper, P2NameWrapper } from './styles'
import { SkullIcon } from '../../components/Icons/SkullIcon'

import styled from 'styled-components'

const getP1dmg = data => data.players[0].damageGiven
const getP2dmg = data => data.players[1].damageGiven
const getP1name = data => data.players[0].name
const getP2name = data => data.players[1].name
const getP1kills = data => data.players[0].kills
const getP2kills = data => data.players[1].kills
export const clearRBGcolors = (name) => name.replaceAll(/\^[Cc]*\d{1,3}/g, '')
const getP1suicides = data => data.players[0].suicides
const getP2suicides = data => data.players[1].suicides

const KillAndSuicidesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Kill1AndSuicidesWrapper = styled(KillAndSuicidesWrapper)`
  grid-column-start: accuracy;
  grid-column-end: gap4;
  grid-row-start: overall-data;
  justify-self: right;
  display: flex;
  flex-direction: row-reverse;
`

const Kill2AndSuicidesWrapper = styled(KillAndSuicidesWrapper)`
  grid-column-start: accuracy1;
  grid-column-end: kills1;
  grid-row-start: overall-data;
`

const SuicidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SuicidesTextWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
`

const Kills = ({ kills, suicides, playerNumber }) => {
  const Wrapper = playerNumber === 0 ? P1KillsCountWrapper : P2KillsCountWrapper
  const KillsWrapper = playerNumber === 0 ? Kill1AndSuicidesWrapper : Kill2AndSuicidesWrapper

  if (suicides === 0) {
    return (
      <KillsWrapper>
        <Wrapper>{ kills }</Wrapper>
      </KillsWrapper>
    )
  }

  return (
    <KillsWrapper>
      <Wrapper>{ kills }</Wrapper>
      <SuicidesWrapper>
        <SkullIcon />
        <SuicidesTextWrapper>{ suicides }</SuicidesTextWrapper>
      </SuicidesWrapper>
    </KillsWrapper>
  )
}

export const StatsTopLine = ({ data }) => {
  const p1Dmg = getP1dmg(data)
  const p2Dmg = getP2dmg(data)
  const p1name = clearRBGcolors(getP1name(data))
  const p2name = clearRBGcolors(getP2name(data))
  const p1kills = getP1kills(data)
  const p2kills = getP2kills(data)
  const p1suicides = getP1suicides(data)
  const p2suicides = getP2suicides(data)

  return (
    <>
      <DmgAndTopGuns damage={ p1Dmg } playerNumber={ 0 } data={ data }/>
      <P1NameWrapper>{ p1name }</P1NameWrapper>
      <Kills kills={p1kills} suicides={p1suicides} playerNumber={0} />
      <Kills kills={p2kills} suicides={p2suicides} playerNumber={1}  />
      <P2NameWrapper>{ p2name }</P2NameWrapper>
      <DmgAndTopGuns damage={ p2Dmg } playerNumber={ 1 } data={ data }/>
      <TableHeaders/>
    </>
  )
}
