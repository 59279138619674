import React from 'react'
import { FindMatchButtonWrapper, LastPlayedMatchHeaderWrapper, TopLineWrapper } from './styles'

const FindMatchButton = () => (
  <FindMatchButtonWrapper onClick={() => console.log('find match clicked')}>
    Find match
  </FindMatchButtonWrapper>
)

export const TopLine = () => (
  <TopLineWrapper>
    <LastPlayedMatchHeaderWrapper>
      Last match played:
    </LastPlayedMatchHeaderWrapper>
    <FindMatchButton />
  </TopLineWrapper>
)
