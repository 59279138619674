import React from 'react'

import { RowsComponents } from './THead/Rows'
import { HeaderComponents } from './THead/Headers'

export const columns = [
  {
    id: 'name',
    header: HeaderComponents.getNameHeader,
    cell: RowsComponents.getNameRow
  },
  {
    id: 'kills',
    header: HeaderComponents.getKillsHeader,
    cell: RowsComponents.getKillsRow
  },
  {
    id: 'deaths',
    header: HeaderComponents.getDeathsHeader,
    cell: RowsComponents.getDeathsRow,
  },
  {
    id: 'score',
    header: HeaderComponents.getScoreHeader,
    cell: RowsComponents.getScoreRow,
  },
  {
    id: 'net',
    header: HeaderComponents.getNetHeader,
    cell: RowsComponents.getNetRow,
  },
  {
    id: 'DG',
    header: HeaderComponents.getDamageGivenHeader,
    cell: RowsComponents.getDamageGivenRow,
  },
  {
    id: 'DT',
    header: HeaderComponents.getDamageTakenHeader,
    cell: RowsComponents.getDamageTakenRow,
  },
  {
    id: 'BW',
    header: HeaderComponents.getBWHeader,
    cell: RowsComponents.getBWRow,
  }
]

export function filterDataByType(data, type) {
  const playersInTeam = data.teams[type].players
  return data.players.filter(player => playersInTeam.includes(player.guid))
}
