import { SmallWrapper } from './SmallWrapper'

export const GrenadeLauncherIcon = ({ small }) => (
  <SmallWrapper small={small} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_80)">
      <path d="M7.51414 6.24946V2.17297L10.6562 2.32943C10.7345 1.57758 10.7649 0.882229 10.8431 0.0738845C13.3812 0.0608467 15.9279 -0.217293 18.4268 0.382446C19.7871 0.708391 20.9996 1.43851 22.2469 2.05563L27.814 4.81095C28.2834 7.85311 29.1178 10.7866 29.6263 13.807C29.9101 15.2712 30.031 16.7622 29.987 18.2529C29.9218 19.3047 29.1482 20.0174 28.6832 21.0734C27.1752 16.558 26.2712 12.0382 24.7979 7.64451C22.1904 6.40157 19.7306 4.88484 16.9231 3.97219C16.7188 4.67623 16.5928 5.23251 16.4537 5.85833C17.9227 6.897 19.4959 7.76619 20.8475 8.93525C22.9466 10.7301 24.0243 13.0682 24.6241 15.7019C25.8714 21.2038 21.8818 28.153 15.7584 29.7175C7.5011 31.8253 0.982195 26.3755 0.113008 19.435C-0.43458 14.9935 1.17776 11.2994 4.60671 8.37462C5.5191 7.59169 6.49119 6.88115 7.51414 6.24946Z" fill="#358F13"/>
    </g>
    <defs>
      <clipPath id="clip0_7_80">
        <rect width="30" height="30.1738" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)