import styled from 'styled-components'

export const SmallGunIconWrapper = styled.div`
  margin-right: 9px;
  &:last-child {
    margin-right: 0;
  }
`

export const TopGunsIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TopGunsDamageWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
`

export const StatsTopLineNicknameWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: right;
  color: #FFFFFF;
  text-transform: lowercase;
`

export const P1NameWrapper = styled(StatsTopLineNicknameWrapper)`
  grid-column-start: hits;
  grid-row-start: overall-data;
`

export const P2NameWrapper = styled(StatsTopLineNicknameWrapper)`
  grid-column-start: kills1;
  grid-row-start: overall-data;
`

const KillsCountWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 86px;
  color: #FFFFFF;
`

export const P1KillsCountWrapper = styled(KillsCountWrapper)`
  grid-column-start: accuracy;
  grid-column-end: weapon-icon;
  justify-self: right;
`

export const P2KillsCountWrapper = styled(KillsCountWrapper)`
  grid-column-start: accuracy1;
  grid-column-end: kills1;
  grid-row-start: overall-data;
`
