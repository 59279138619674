import React from 'react'
import { AwardList } from './AwardList'

import styled from 'styled-components'

const Separator = styled.div`
  width: 2px;
  height: 91px;

  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 5px;
`

const AwardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Awards = () => (
  <AwardsWrapper>
    <AwardList playerNumber={ 0 }/>
    <Separator/>
    <AwardList playerNumber={ 1 }/>
  </AwardsWrapper>
)
