import { weaponsMap } from '../../data'
import { SmallGunIconWrapper, TopGunsIconsWrapper } from '../../Duel/MatchStats/styles'
import React from 'react'
import { MachinegunIcon } from '../../components/Icons/Machinegun'
import { ShotgunIcon } from '../../components/Icons/Shotgun'
import { HyperBlasterIcon } from '../../components/Icons/HyperBlaster'
import { RocketLauncherIcon } from '../../components/Icons/RocketLauncher'
import { RailgunIcon } from '../../components/Icons/Railgun'
import { LightningGunIcon } from '../../components/Icons/LightningGun'
import { GrenadeLauncherIcon } from '../../components/Icons/GrenadeLauncher'
import { NailgunIcon } from '../../components/Icons/Nailgun'
import styled from 'styled-components'

const getTopGuns = (row) => {
  const topGuns = []
  const weapons = row.original.weapons

  for (const gun in weapons) {
    topGuns.push({
      name: weaponsMap[gun],
      kills: weapons[gun].kills
    })
  }

  const sortedByKillsCount = topGuns.sort((a, b) => b.kills - a.kills)
  const filteredWith0Kills = sortedByKillsCount.filter(gun => gun.kills !== 0)

  return filteredWith0Kills.slice(0, 3)
}

const icons = {
  MG: <MachinegunIcon small/>,
  Shotgun: <ShotgunIcon small/>,
  HyperBlaster: <HyperBlasterIcon small/>,
  Rocket: <RocketLauncherIcon small/>,
  Railgun: <RailgunIcon small/>,
  LG: <LightningGunIcon small/>,
  GL: <GrenadeLauncherIcon small/>,
  Nailgun: <NailgunIcon small/>
}

const TopGunsIcons = ({ topGuns }) => {
  return (
    <TopGunsIconsWrapper>
      {
        topGuns.map((gun, index) => (
            <SmallGunIconWrapper key={`${gun.name}-${index}`}>
              {icons[gun.name]}
            </SmallGunIconWrapper>
          )
        )
      }
    </TopGunsIconsWrapper>
  )
}

const TDMGunsWrapper = styled.div`
  svg {
    height: 20px;
    width: 20px;
  }
`

export const TopGuns = ({ row }) => {
  const topGuns = getTopGuns(row)

  return (
    <TDMGunsWrapper>
      <TopGunsIcons topGuns={topGuns}/>
    </TDMGunsWrapper>
  )
}
