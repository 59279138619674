import { SmallWrapper } from './SmallWrapper'

export const ShotgunIcon = ({ small }) => (
  <SmallWrapper small={small} width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_61)">
      <path d="M0 22.4071V0H8.44379V22.4071H0ZM1.87766 1.93155V14.749H6.58599V1.93155H1.87766Z" fill="#FF8001"/>
      <path d="M0 23.7033H8.44379V25.6349H0V23.7033Z" fill="#FF8001"/>
      <path d="M10.7951 22.4071V0H19.2389V22.4071H10.7951ZM12.6728 1.92871V14.749H17.3811V1.93155L12.6728 1.92871Z"
            fill="#FF8001"/>
      <path d="M10.7951 23.7033H19.2389V25.6349H10.7951V23.7033Z" fill="#FF8001"/>
      <path d="M21.5562 22.4071V0H30V22.4071H21.5562ZM23.4339 1.92871V14.749H28.1422V1.93155L23.4339 1.92871Z"
            fill="#FF8001"/>
      <path d="M21.5562 23.7033H30V25.6349H21.5562V23.7033Z" fill="#FF8001"/>
    </g>
    <defs>
      <clipPath id="clip0_7_61">
        <rect width="30" height="25.6349" fill="white"/>
      </clipPath>
    </defs>
  </SmallWrapper>
)