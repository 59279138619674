import { EmptyWrapper, NextPrevMatchWrapper } from './styles'
import { NextMatch, PrevMatch } from './index'
import { NextPrevMatchAPI } from './api'
import { Link } from 'react-router-dom'

export const NextMatchComponent = ({ nextStatus, nextData }) => {
  if (nextStatus === 'loading' || nextStatus === 'error') {
    return <EmptyWrapper/>
  }
  const id = nextData?.nextMatchId
  if (id === undefined || id === null) {
    return <EmptyWrapper/>
  }

  return (
    <div>
      <Link to={`/match/${id}`}>next match ></Link>
    </div>
  )
}

export const PrevMatchComponent = ({ prevStatus, prevData }) => {
  if (prevStatus === 'loading' || prevStatus === 'error') {
    return <EmptyWrapper/>
  }

  const id = prevData?.previousMatchId
  if (id === undefined || id === null) {
    return <EmptyWrapper/>
  }

  return (
    <div>
      <Link to={`/match/${id}`}>{'< prev match'}</Link>
    </div>
  )
}

export const NextPrevMatchComponent = ({ nextStatus, nextData, prevStatus, prevData }) => (
  <NextPrevMatchWrapper>
    <PrevMatchComponent nextStatus={nextStatus} nextData={nextData}/>
    <NextMatchComponent prevStatus={prevStatus} prevData={prevData}/>
  </NextPrevMatchWrapper>
)
