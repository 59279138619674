import React, { useCallback, useMemo, Fragment, useState } from 'react'
import { MarineKillCount } from './KillCount/MarineKillCount'
import { StroggKillCount } from './KillCount/StroggKillCount'
import { TDMStatsWrapper } from './styles'
import { clearRBGcolors } from '../../Duel/MatchStats/StatsTopLine'
import styled from 'styled-components'
import { SkullIcon } from '../../components/Icons/SkullIcon'
import { TopGuns } from '../TopGuns'
import { MainTable } from './MainTable'

function getData(data, teamName) {
  if (data.teams[teamName]) {
    const teamPlayers = data.teams[teamName].players

    return teamPlayers.map(playerGuid => {
      return data.players.find(player => player.guid === playerGuid)
    })
  } else {
    return []
  }
}

const HeaderTextWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
`

const TopTableStyles = styled.div`
  table {
    border: none;
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      font-family: 'SF Pro Rounded';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
    }

    tbody tr[role="row"] {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
`

const KillsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .kills-stats {
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .team-kills {
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-variant: all-small-caps;
    color: #FF5678;
  }
`

const DeathsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 20px;
    height: 14px;
    margin-left: 8px;
  }
`

const DeathCountWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-variant: all-small-caps;
  color: #FFFFFF;
`

const SuicideCountWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
`

const ScoreWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-variant: all-small-caps;
  color: #FFFFFF;
`

const DamageGivenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DamageGivenTextWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-variant: all-small-caps;
  color: #FFFFFF;
`

const TeamDamageWrapper = styled.div`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-variant: all-small-caps;
  color: #FF5678;
`

const DamageTakenWrapper = styled.div``


export const TDMStats = ({ data }) => {

  return (
    <TDMStatsWrapper>
      <MarineKillCount killCount={data.teams.Marine.score}/>
      <MainTable data={data} type="Marine"/>
      <StroggKillCount killCount={data.teams.Strogg.score}/>
      <MainTable data={data} type="Strogg"/>
    </TDMStatsWrapper>
  )
}
