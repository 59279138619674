import { weaponsMap } from '../../../../data'
import { TopGunsIcons } from '../../../../Duel/MatchStats/DmgAndTopGuns'

const getTopGuns = (weapons) => {
  const topGuns = []

  for (const gun in weapons) {
    topGuns.push({ name: weaponsMap[gun], kills: weapons[gun].kills })
  }

  const sortedByKillsCount = topGuns.sort((a, b) => b.kills - a.kills)
  const filteredWith0Kills = sortedByKillsCount.filter(gun => gun.kills !== 0)

  return filteredWith0Kills.slice(0, 3)
}

export const TopGuns = ({ row }) => {
  const topGuns = getTopGuns(row.original.weapons)

  return (
    <TopGunsIcons topGuns={topGuns} />
  )
}
