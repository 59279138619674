import React from 'react'
import {useParams} from "react-router";
import {useGetMatch} from "./api";
import {Duel} from "../Duel";
import { TDM } from '../TDM'

export const Match = () => {
  const {matchId} = useParams()

  const { status, data, error } = useGetMatch(matchId)
  if (!data) {
    return null
  }

  if (data.type === 'Team DM') {
    return <TDM matchId={matchId} />
  } else {
    return <Duel />
  }
}
